import { loaderEvent, makeAPICall } from '@gokwik/utilities'
import { API_ENDPOINTS } from './endpoints'
import { getCall, postCall } from './service'

export const getDarkStoresGroup = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.DARKSTORE_GROUP, body)
  return response.data?.data
}

// stockout Table
export const getStockoutTableData = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.STOCKOUT_TABLE, body)
  return response.data?.data
}

// storePincodeMap table
// export const getStorePincodeMapTable = async (params): Promise<any> => {
//   const response = await getCall(API_ENDPOINTS.STOCKPINCODEMAP_TABLE, params)
//   return response.data
// }

export const getStorePincodeMapTable = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.STOCKPINCODEMAP_TABLE, body)
  return response.data?.data
}

export const getStockOutTrendsFilterDetails = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.STOCKOUT_TRENDS_FILTER, body)
  return response.data?.data
}

export const getStockOutTrends = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.GET_STOCKOUT_TRENDS, body)
  return response.data?.data
}

export const getDarkStoreCount = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.GET_DARKSTORE_COUNT, body)
  return response.data
}

export const getInstamartUnistedByProductUrls = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.INSTAMART_UNLISTED_BY_PRODUCTURL, body)
  return response.data
}

export const getInstamartUnlistedByDarkStore = async (body): Promise<any> => {
  const response = await postCall(API_ENDPOINTS.INSTAMART_UNLISTED_BY_DARKSTORE, body)
  return response.data
}
