import { Button, DatePicker, Form, Select, Table, Tooltip } from 'gokwik-ui-kit'
import React from 'react'
import dayjs from 'dayjs'

const Reports = () => {
  const [form] = Form.useForm()
  const { RangePicker } = DatePicker

  const columns = [
    {
      title: 'Name',
      dataIndex: 'reportName',
      key: 'name',
      width: 200,
      ellipsis: true,
      render: (text: string) => (
        <Tooltip placement='topLeft' title={text}>
          <div className='truncate' style={{ maxWidth: 180 }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    { title: 'Generated On', dataIndex: 'generatedOn', key: 'generatedOn' },
    { title: 'Generated By', dataIndex: 'generatedBy', key: 'generatedBy' },
    { title: 'Merchant', dataIndex: 'merchantName', key: 'merchant' },
    { title: 'Type', dataIndex: 'reportType', key: 'type' },
    {
      title: 'Date Range',
      dataIndex: 'dateRange',
      key: 'dateRange',
      render: (_, record) => {
        const { from, to } = record.dateRange
        return `${dayjs(from).format('DD/MM/YYYY')} - ${dayjs(to).format('DD/MM/YYYY')}`
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Button
          type={record.status === 'pending' ? 'primary' : 'default'}
          size='small'
          className='rounded-full px-3 py-1 text-xs'
        >
          {record.status}
        </Button>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      // render: (_, record) => <a onClick={() => handleAction(record)}>View</a>,
    },
  ]

  return (
    <div className='overflow-auto w-full dashboard-reports'>
      <div className='bg-white p-4'>
        <Form
          form={form}
          // initialValues={{ dateRange: defaultDateRange, reportType: 'shipment' }}
          layout='vertical'
          // onFinish={handleSubmit}
        >
          <div className='flex flex-wrap justify-between items-center gap-4'>
            {/* Left Side: Date Range & Report Type */}
            <div className='flex flex-wrap gap-4 items-center'>
              <Form.Item name='dateRange' className='mb-0'>
                <RangePicker className='w-[250px]' format='YYYY-MM-DD HH:mm:ss' />
              </Form.Item>
              <Form.Item name='reportType' className='mb-0'>
                <Select style={{ width: '150px' }} placeholder='Report Type'>
                  {/* {reportsTypes.map(({ value, text }) => (
                    <Select.Option key={value} value={value}>
                      {text}
                    </Select.Option>
                  ))} */}
                </Select>
              </Form.Item>
            </div>
            {/* Submit Button */}
            <Form.Item className='mb-0'>
              <Button htmlType='submit' variant='primary' className='flex items-center px-4 py-2'>
                <span className='text-white'>Generate Report</span>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>

      {/* Reports Table */}
      <div className='mt-5 bg-white rounded overflow-clip'>
        <p className='p-4 pl-2 text-sm'>Reports generated in last 7 days</p>
        <Table
          className='ordersTable'
          columns={columns}
          // dataSource={merchantReports}
          pagination={{
            showSizeChanger: false,
            position: ['topRight', 'bottomRight'],
            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
          }}
          rowClassName={(record) =>
            (new Date().getTime() - new Date(record.generatedOn).getTime()) / 1000 < 30 &&
            `animate-bg-${record.status.toLowerCase()}`
          }
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  )
}

export default Reports
