import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Select, Card, Row, Col, Form, Space, Button, DatePicker } from 'gokwik-ui-kit'
import { getStockOutTrends, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { FilterData, StockOutTrend } from './types/types'

const { Option } = Select
// const { RangePicker } = DatePicker

const Inventory = () => {
  const [stockOutTrendsData, setStockOutTrendsData] = useState<StockOutTrend[]>([])
  const [filterData, setFilterData] = useState<FilterData>({
    uniqueMerchantIds: [],
    uniqueStates: [],
    uniqueProducts: [],
  })
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  const [selectedStore, setSelectedStore] = useState('instamart')
  const [selectedMerchant, setSelectedMerchant] = useState('noise')

  const stores = ['instamart', 'zepto', 'blinkit']

  // Initialize default date (today)
  const defaultDate = dayjs()
  const fetchStockOutTrends = async (values) => {
    setLoading(true)
    try {
      // Use selected date or default to today
      const selectedDate = values.date || defaultDate

      // Calculate 'from' date (1 day before 'to' date)
      const fromDate = dayjs(selectedDate).subtract(1, 'day')

      const updatedPayload = {
        ...values,
        storeId: selectedStore,
        groupBy: 'city',
        topStockOutSkus: true,
        from: fromDate.hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: selectedDate.hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      }

      delete updatedPayload.dateRange // Remove dateRange
      const response = await getStockOutTrends(updatedPayload)
      setStockOutTrendsData(response?.products || [])
    } catch (error) {
      console.error('Error in calling fetchStockOutTrends api', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStockOutTrendsFilterDetails = async (storeId) => {
    try {
      const response = await getStockOutTrendsFilterDetails({
        storeIds: [storeId],
        // merchantIds: [selectedMerchant],
      })
      setFilterData(response)
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Citywise Inventory',
        href: 'kwikcomm/citywise-inventory',
        text: 'kwik Comm Citywise Inventory',
      },
    ])
    fetchStockOutTrendsFilterDetails(selectedStore)
    // Set initial date range in form
    form.setFieldsValue({ dateRange: defaultDate })
    fetchStockOutTrends({ states: ['UTTAR PRADESH'], dateRange: defaultDate })
    form.resetFields()
  }, [selectedStore, form])

  const transformedStockOutTrendsData = stockOutTrendsData?.map((item) => ({
    city: item?._id?.city,
    inventory: Math.round(item?.inventory),
  }))

  const maxInventory = Math.max(...(transformedStockOutTrendsData.map((item) => item.inventory) || [0]))

  const getTicksArray = (maxValue) => {
    if (maxValue <= 100) {
      return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    } else if (maxValue <= 1000) {
      return [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
    } else if (maxValue <= 10000) {
      return [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
    } else {
      return [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000]
    }
  }

  const handleStoreChange = (value) => {
    setSelectedStore(value)
  }

  const handleSelectedMerchant = (value) => {
    console.log(value)
    setSelectedMerchant(value)
  }
  const onFinish = (values) => {
    fetchStockOutTrendsFilterDetails(selectedStore)
    fetchStockOutTrends(values)
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({ dateRange: defaultDate, states: ['UTTAR PRADESH'] })
    fetchStockOutTrends({ dateRange: defaultDate, states: ['UTTAR PRADESH'] })
  }

  return (
    <div className=''>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <div className='flex justify-between items-center mb-6'>
              {/* <h1 className='text-2xl font-semibold m-0'>Product Stock Inventory</h1> */}
              <Select value={selectedStore} onChange={handleStoreChange} className='w-40'>
                {stores.map((store) => (
                  <Option key={store} value={store}>
                    {store.charAt(0).toUpperCase() + store.slice(1)}
                  </Option>
                ))}
              </Select>
            </div>

            <Form
              form={form}
              onFinish={onFinish}
              layout='vertical'
              initialValues={{
                dateRange: defaultDate,
              }}
            >
              <Row gutter={16}>
                {/* Date Range Picker */}
                <Col xs={14} sm={5}>
                  <Form.Item
                    name='dateRange'
                    label='Date Range'
                    rules={[{ required: true, message: 'Please select date range' }]}
                  >
                    <DatePicker className='w-full' format='YYYY-MM-DD HH:mm:ss' />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={8}>
                  <Form.Item name='merchantId' label='Merchant ID'>
                    <Select
                      showSearch
                      placeholder='Select merchant ID'
                      allowClear
                      optionFilterProp='children'
                      onChange={handleSelectedMerchant}
                    >
                      {filterData?.uniqueMerchantIds?.map((id) => (
                        <Option key={id} value={id}>
                          {id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col xs={14} sm={5}>
                  <Form.Item name='states' label='State' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select State' allowClear optionFilterProp='children'>
                      {filterData?.uniqueStates?.map((state) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={14} sm={5}>
                  <Form.Item name='productUrls' label='Product URLs' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select product URLs' allowClear optionFilterProp='children'>
                      {filterData?.uniqueProducts?.map((url) => (
                        <Option key={url.productUrl} value={url.productUrl}>
                          {url.productName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Space>
                      <Button type='primary' htmlType='submit'>
                        Apply Filters
                      </Button>
                      <Button onClick={handleReset}>Reset</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card loading={loading}>
            <div style={{ height: 390 }}>
              <ResponsiveContainer>
                <LineChart
                  data={transformedStockOutTrendsData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 5,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='city'
                    tick={{ fontSize: 11 }}
                    interval={0}
                    tickFormatter={(cityName) => (cityName.length > 10 ? `${cityName.slice(0, 10)}...` : cityName)}
                    label={{
                      value: 'Cities',
                      position: 'insideBottom',
                      offset: -10,
                    }}
                  />
                  <YAxis
                    domain={[0, getTicksArray(maxInventory)[10]]}
                    ticks={getTicksArray(maxInventory)}
                    label={{
                      value: 'Inventory',
                      angle: -90,
                      position: 'insideLeft',
                    }}
                  />
                  <Tooltip />
                  <Line type='monotone' dataKey='inventory' stroke='#1890ff' dot={true} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Inventory
