import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Select, Card, Row, Col, Form, Space, Button, DatePicker } from 'gokwik-ui-kit'
import { getStockOutTrends, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { FilterData } from './types/types'

const { Option } = Select
const { RangePicker } = DatePicker
const Inventory = () => {
  const [stockOutTrendsData, setStockOutTrendsData] = useState([])
  const [filterData, setFilterData] = useState<FilterData>({
    uniqueMerchantIds: [],
    uniqueStates: [],
    uniqueProducts: [],
  })
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  const [selectedStore, setSelectedStore] = useState('instamart')
  const [selectedMerchant, setSelectedMerchant] = useState('noise')

  const stores = ['instamart', 'zepto', 'blinkit']

  // Initialize default date range (2 days before today)
  const defaultDateRange = [dayjs().subtract(2, 'day'), dayjs()]
  const fetchStockOutTrends = async (values) => {
    setLoading(true)
    try {
      const dateRange = values.dateRange || defaultDateRange
      const formattedValues = {
        ...values,
        from: dateRange[0].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dateRange[1].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        storeId: selectedStore,
      }
      delete formattedValues.dateRange // Remove dateRange from payload
      const response = await getStockOutTrends(formattedValues)
      setStockOutTrendsData(response?.products || [])
    } catch (error) {
      console.error('Error in calling fetchStockOutTrends api', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStockOutTrendsFilterDetails = async (storeId) => {
    try {
      const response = await getStockOutTrendsFilterDetails({
        storeIds: [storeId],
        // merchantIds: [selectedMerchant],
      })
      setFilterData(response)
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Date Wise Inventory',
        href: '/kwikcomm/inventory',
        text: 'kwikcomm Date Wise Inventory',
      },
    ])
    fetchStockOutTrendsFilterDetails(selectedStore)
    // Set initial date range in form
    form.setFieldsValue({ dateRange: defaultDateRange })
    fetchStockOutTrends({ dateRange: defaultDateRange })
    form.resetFields()
  }, [selectedStore, form])

  const transformedStockOutTrendsData = stockOutTrendsData?.map((item) => ({
    date: item?._id?.createdAt,
    inventory: Math.round(item?.inventory),
  }))

  const maxInventory = Math.max(...(transformedStockOutTrendsData.map((item) => item.inventory) || [0]))

  const getTicksArray = (maxValue) => {
    if (maxValue <= 100) {
      return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    } else if (maxValue <= 1000) {
      return [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
    } else if (maxValue <= 10000) {
      return [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
    } else {
      return [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000]
    }
  }

  const handleStoreChange = (value) => {
    setSelectedStore(value)
  }

  const handleSelectedMerchant = (value) => {
    console.log(value)
    setSelectedMerchant(value)
  }
  const onFinish = (values) => {
    fetchStockOutTrendsFilterDetails(selectedStore)
    fetchStockOutTrends(values)
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({ dateRange: defaultDateRange })
    fetchStockOutTrends({ dateRange: defaultDateRange })
  }

  return (
    <div className=''>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <div className='flex justify-between items-center mb-6'>
              <Select value={selectedStore} onChange={handleStoreChange} className='w-40'>
                {stores.map((store) => (
                  <Option key={store} value={store}>
                    {store.charAt(0).toUpperCase() + store.slice(1)}
                  </Option>
                ))}
              </Select>
            </div>

            <Form
              form={form}
              onFinish={onFinish}
              layout='vertical'
              initialValues={{
                dateRange: defaultDateRange,
              }}
            >
              <Row gutter={16}>
                {/* Date Range Picker */}
                <Col xs={14} sm={4}>
                  <Form.Item
                    name='dateRange'
                    label='Date Range'
                    rules={[{ required: true, message: 'Please select date range' }]}
                  >
                    <RangePicker className='w-full' showTime format='YYYY-MM-DD HH:mm:ss' />
                  </Form.Item>
                </Col>
                {/* <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='merchantId' label='Merchant ID'>
                    <Select
                      showSearch
                      placeholder='Select merchant ID'
                      allowClear
                      optionFilterProp='children'
                      onChange={handleSelectedMerchant}
                    >
                      {filterData?.uniqueMerchantIds?.map((id) => (
                        <Option key={id} value={id}>
                          {id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='cities' label='City' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select city' allowClear optionFilterProp='children'>
                      {filterData?.uniqueCities?.map((city) => (
                        <Option key={city} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='productUrls' label='Product URLs' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select product URLs' allowClear optionFilterProp='children'>
                      {filterData?.uniqueProducts?.map((url) => (
                        <Option key={url.productUrl} value={url.productUrl}>
                          {url.productName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Space>
                      <Button type='primary' htmlType='submit'>
                        Apply Filters
                      </Button>
                      <Button onClick={handleReset}>Reset</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card loading={loading}>
            <div style={{ height: 395 }}>
              <ResponsiveContainer>
                <LineChart
                  data={transformedStockOutTrendsData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 5,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='date'
                    label={{
                      value: 'Dates',
                      position: 'insideBottom',
                      offset: -10,
                    }}
                  />
                  <YAxis
                    domain={[0, getTicksArray(maxInventory)[10]]}
                    ticks={getTicksArray(maxInventory)}
                    label={{
                      value: 'Inventory',
                      angle: -90,
                      position: 'insideLeft',
                    }}
                  />
                  <Tooltip />
                  <Line type='monotone' dataKey='inventory' stroke='#1890ff' dot={true} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Inventory
