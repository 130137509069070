import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Select, Card, Row, Col, Form, Space, Button, DatePicker } from 'gokwik-ui-kit'
import { getStockOutTrends, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { FilterData } from './types/types'
import { FormValues } from './types/types'

const { Option } = Select
// const { RangePicker } = DatePicker

const CitywiseStockoutTrends = () => {
  const [stockOutTrendsData, setStockOutTrendsData] = useState([])
  const [filterData, setFilterData] = useState<FilterData>({
    uniqueMerchantIds: [],
    uniqueStates: [],
    uniqueProducts: [],
    uniqueCities: [],
    uniqueDarkStores: [],
  })
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm<FormValues>()
  const [selectedStore, setSelectedStore] = useState('instamart')
  const [selectedMerchant, setSelectedMerchant] = useState('noise')

  const stores = ['instamart', 'zepto', 'blinkit']

  // console.log(filterData, 'the game of filter data ')
  // Initialize default date range (2 days before today)
  // Initialize default date (today)
  const defaultDate = dayjs()

  const fetchStockOutTrends = async (values, download = false) => {
    setLoading(true)
    try {
      const selectedDate = values.date || defaultDate
      const fromDate = dayjs(selectedDate).subtract(1, 'day')
      const updatedPayload = {
        ...values,
        from: fromDate.hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: selectedDate.hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        groupBy: 'city',
        topStockOutSkus: true,
        download: download === true ? true : undefined,
        // states: [values?.state || 'UTTAR PRADESH'],
      }
      delete updatedPayload.date // Remove dateRange from payload

      const response = await getStockOutTrends(updatedPayload)
      setStockOutTrendsData(response?.products || [])
      if (download === true) {
        const res = await fetch(`${process.env.BASE_URL}/getStockOutTrends`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
          },
          body: JSON.stringify(updatedPayload),
        })

        //get the response as text for CSV
        const csvText = await res.text()
        const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'CitywiseStockout_data.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        setLoading(false)
        return
      }
    } catch (error) {
      console.error('Error in calling fetchStockOutTrends api', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStockOutTrendsFilterDetails = async (formValues = form.getFieldsValue()) => {
    try {
      const payload = {
        ...(formValues.storeId && { storeIds: [formValues.storeId] }),
        ...(formValues.states && { states: formValues.states }),
        ...(formValues.darkStoreIds && { darkStoreIds: formValues.darkStoreIds }),
      }
      const response = await getStockOutTrendsFilterDetails(payload)
      console.log(response, 'check it out')

      // Filter unique dark stores by pincode
      const uniqueDarkStores = Object.values(
        response.uniqueDarkStores.reduce((acc, store) => {
          acc[store.storeNamePincode] = store // Keep only the last occurrence
          return acc
        }, {}),
      )
      setFilterData({
        ...response,
        uniqueDarkStores,
      })
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Citywise Stockout Trends',
        href: '/kwikcomm/citywise-stockout-trends',
        text: ' Citywise Stockout Trends',
      },
    ])

    // fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    // Set initial date range in form and fetch initial data
    form.setFieldsValue({
      storeId: selectedStore,
      // states: ['UTTAR PRADESH'],
      // date: defaultDate,
    })

    // fetchStockOutTrends({ states: ['UTTAR PRADESH'], dateRange: defaultDate, storeId: selectedStore })
    // form.resetFields()
  }, [])

  // Effect for store changes
  useEffect(() => {
    // console.log('select use effect')
    // Reset form fields except storeId
    form.setFieldsValue({
      // dateRange: defaultDate,
      storeId: selectedStore,
      states: undefined,
      darkStoreIds: undefined,
      productUrls: undefined,
    })

    fetchStockOutTrendsFilterDetails({ storeId: selectedStore, states: ['UTTAR PRADESH'] })
    form.setFieldsValue({
      storeId: selectedStore,
      states: ['UTTAR PRADESH'],
      // Set initial state value here
      // date: defaultDate,
    })
    fetchStockOutTrends({ dateRange: defaultDate, storeId: selectedStore, states: ['UTTAR PRADESH'] })
  }, [selectedStore])

  const transformedStockOutTrendsData = stockOutTrendsData?.map((item) => ({
    city: item?._id?.city,
    outOfStockPercentage: Number(item?.outOfStockPercentage.toFixed(0)),
  }))

  const handleStoreChange = (value) => {
    setSelectedStore(value)
  }

  // const onFinish = (values) => {
  //   fetchStockOutTrendsFilterDetails(values)
  //   fetchStockOutTrends(values)
  // }

  const handleFormValuesChange = (changedValues, allValues) => {
    if ('storeId' in changedValues) {
      setSelectedStore(changedValues.storeId)
    } else if ('states' in changedValues) {
      // Reset darkStoreIds when cities change
      form.setFieldsValue({ darkStoreIds: undefined, productUrls: undefined })

      fetchStockOutTrendsFilterDetails({ ...allValues, darkStoreIds: undefined, productUrls: undefined })
      fetchStockOutTrends({ ...allValues, darkStoreIds: undefined, productUrls: undefined })
    } else if ('darkStoreIds' in changedValues) {
      // Reset darkStoreIds when cities change
      form.setFieldsValue({ productUrls: undefined })

      fetchStockOutTrendsFilterDetails({ ...allValues, productUrls: undefined })
      fetchStockOutTrends({ ...allValues, productUrls: undefined })
    } else {
      // Fetch data for other changes
      fetchStockOutTrendsFilterDetails(allValues)
      fetchStockOutTrends(allValues)
    }
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({ states: ['UTTAR PRADESH'] })
    fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    fetchStockOutTrends({ dateRange: defaultDate, states: ['UTTAR PRADESH'], storeId: selectedStore })
  }

  const handleApplyFilters = async (download = false) => {
    try {
      const values = await form.validateFields()
      console.log(values, download)
      fetchStockOutTrends(values, download)
    } catch (error) {
      console.error('Error in applying filters:', error)
    }
  }
  return (
    <div className=''>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Form
              form={form}
              // onFinish={onFinish}
              layout='vertical'
              initialValues={{ states: ['UTTAR PRADESH'], date: defaultDate }}
              onValuesChange={handleFormValuesChange}
              onFinish={handleApplyFilters}
            >
              <Row gutter={16}>
                <Col xs={14} sm={4}>
                  <Form.Item
                    name='storeId'
                    initialValue={selectedStore}
                    label=' Marketplace'
                    // normalize={(value) => (value ? [value] : [])}
                  >
                    <Select onChange={handleStoreChange} className='w-40'>
                      {stores.map((store) => (
                        <Option key={store} value={store}>
                          {store.charAt(0).toUpperCase() + store.slice(1)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* Date Range Picker */}
                <Col xs={14} sm={4}>
                  <Form.Item name='date' label='Date' rules={[{ required: true, message: 'Please select a date' }]}>
                    <DatePicker
                      className='w-full'
                      format='YYYY-MM-DD HH:mm:ss'
                      disabledDate={(current) => current && current > dayjs().endOf('day')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='states' label='State' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select state' optionFilterProp='children'>
                      {filterData?.uniqueStates?.map((state) => (
                        <Option key={state} value={state}>
                          {state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    name='darkStoreIds'
                    label='Dark Store'
                    valuePropName='value'
                    normalize={(value) => (value ? [value] : [])}
                  >
                    <Select placeholder='Select darkstore ' optionFilterProp='children'>
                      {filterData?.uniqueDarkStores?.map((url) => (
                        <Option key={url.storeNameAddress} value={url.darkStoreId}>
                          {url.storeNameAddress}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={14} sm={16}>
                  {' '}
                  <Form.Item name='productUrls' label='Product Name ' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select product ' allowClear optionFilterProp='children'>
                      {filterData?.uniqueProducts?.map((url) => (
                        <Option key={url.productUrl} value={url.productUrl}>
                          {url.productName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8} style={{ display: 'flex', alignItems: 'end' }}>
                  <Form.Item style={{ display: 'flex', gap: '10px' }}>
                    <Space>
                      <Button type='primary' htmlType='submit'>
                        Apply Filters
                      </Button>
                      <Button onClick={handleReset}>Reset</Button>
                      {/* <Button onClick={() => handleApplyFilters(true)}>Download</Button> */}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card loading={loading}>
            <div style={{ height: 390 }}>
              <ResponsiveContainer>
                <LineChart
                  data={transformedStockOutTrendsData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 5,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='city'
                    tick={{ fontSize: 10 }}
                    interval={0}
                    tickFormatter={(cityName) => (cityName.length > 10 ? `${cityName.slice(0, 10)}...` : cityName)}
                    label={{
                      value: 'Cities',
                      position: 'insideBottom',
                      offset: -10,
                    }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    label={{
                      value: 'Out of Stock (%)',
                      angle: -90,
                      position: 'insideLeft',
                      style: {
                        fontWeight: 600,
                        fontSize: 14,
                        fill: '#333',
                      },
                    }}
                  />
                  <Tooltip
                    formatter={(value, name, props) => {
                      // console.log('graph props', props)
                      // Only return the date value, not the percentage
                      return ['', props.payload.city]
                    }}
                    labelFormatter={(label) => ''}
                    contentStyle={{
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      border: '1px solid #ccc',
                      borderRadius: 4,
                      boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                    }}
                  />
                  <Line
                    type='monotone'
                    dataKey='outOfStockPercentage'
                    stroke='#1890ff'
                    name=' '
                    dot={{
                      r: 4,
                      stroke: '#1890ff',
                      strokeWidth: 2,
                      fill: 'white',
                    }}
                    activeDot={{
                      r: 6,
                      stroke: '#1890ff',
                      strokeWidth: 2,
                      fill: 'white',
                    }}
                    label={{
                      position: 'top',
                      fill: '#000',
                      fontSize: 14,
                      fontWeight: 'bold',
                      formatter: (value) => `${value}%`,
                      dx: 10,
                      dy: -8,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default CitywiseStockoutTrends
