import { loaderEvent, makeAPICall } from '@gokwik/utilities'
import { axiosInstance } from './config'
import { AxiosRequestConfig } from 'axios'

export const getCall = async (url, params): Promise<any> => {
  let queryParamsToAdd = ''
  Object.keys(params).forEach((element) => {
    queryParamsToAdd += `${element}=${params[element]}&`
  })
  url += `?${queryParamsToAdd}`

  loaderEvent({ count: 1 })
  const response = await axiosInstance({
    method: 'get',
    url: process.env.BASE_URL + url,
  })
  loaderEvent({ count: -1 })

  return response
}

export const postCall = async (
  url: string,
  body: object,
  headers: object = {},
  upload: boolean = false,
): Promise<any> => {
  try {
    // Get merchant-mid from localStorage
    const merchantMid = localStorage.getItem('merchant-mid')

    // Combine default headers with passed headers and merchant-mid
    const combinedHeaders = {
      ...headers,
      ...(merchantMid && { 'merchant-mid': merchantMid }),
    }

    // Start loader
    loaderEvent({ count: 1 })

    // Configure axios request
    const config: AxiosRequestConfig = {
      method: 'post',
      url: process.env.BASE_URL + url,
      data: body,
      headers: combinedHeaders,
      withCredentials: true, // Enable sending cookies
    }

    const response = await axiosInstance(config)

    return response
  } catch (error) {
    // Handle errors appropriately
    console.error('Error in POST call:', error)
    throw error
  } finally {
    // Ensure loader is always removed
    loaderEvent({ count: -1 })
  }
}
