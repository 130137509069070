import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Select, Card, Row, Col, Form, Button, Space, DatePicker, Flex } from 'gokwik-ui-kit'
import { getStockOutTrends, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { FilterData, FormValues } from './types/types'
import { updateBreadcrumbs } from '@gokwik/utilities'

const { Option } = Select
const { RangePicker } = DatePicker

const StockoutTrends = () => {
  const [stockOutTrendsData, setStockOutTrendsData] = useState([])
  const [filterData, setFilterData] = useState<FilterData>({
    uniqueMerchantIds: [],
    uniqueStates: [],
    uniqueProducts: [],
    uniqueCities: [],
    uniqueDarkStores: [],
  })
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm<FormValues>()
  const [selectedStore, setSelectedStore] = useState('zepto')

  const stores = ['instamart', 'zepto', 'blinkit']

  // Initialize default date range (2 days before today)
  const defaultDateRange = [dayjs().subtract(2, 'day'), dayjs()]

  const fetchStockOutTrends = async (values, download = false) => {
    setLoading(true)
    try {
      const dateRange = values.dateRange || defaultDateRange
      const formattedValues = {
        ...values,
        from: dateRange[0].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dateRange[1].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        downloadAll: download === true ? true : undefined,
      }

      delete formattedValues.dateRange // Remove dateRange from payload

      const response = await getStockOutTrends(formattedValues)
      setStockOutTrendsData(response?.products || [])

      if (download === true) {
        const res = await fetch(`${process.env.BASE_URL}/getStockOutTrends`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
          },
          body: JSON.stringify(formattedValues),
        })

        //get the response as text for CSV
        const csvText = await res.text()
        const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'getStockOutTrends_data.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        setLoading(false)
        return
      }
    } catch (error) {
      console.error('Error in calling fetchStockOutTrends api', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStockOutTrendsFilterDetails = async (formValues = form.getFieldsValue()) => {
    try {
      const payload = {
        storeIds: [formValues.storeId || selectedStore],
        ...(formValues.cities && {
          cities: Array.isArray(formValues.cities) ? formValues.cities : [formValues.cities],
        }),
        ...(formValues.darkStoreIds && {
          darkStoreIds: Array.isArray(formValues.darkStoreIds) ? formValues.darkStoreIds : [formValues.darkStoreIds],
        }),
      }
      const response = await getStockOutTrendsFilterDetails(payload)
      setFilterData(response)
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Date Wise Stockout Trends',
        href: '/kwikcomm/datewise-stockout-trends',
        text: ' Date Wise Stockout Trends',
      },
    ])

    // Set initial date range in form and fetch initial data
    form.setFieldsValue({
      dateRange: defaultDateRange,
      storeId: selectedStore,
    })

    fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    fetchStockOutTrends({ dateRange: defaultDateRange, storeId: selectedStore })
  }, [])

  // Effect for store changes
  useEffect(() => {
    console.log('select use effect')
    // Reset form fields except storeId
    form.setFieldsValue({
      dateRange: defaultDateRange,
      storeId: selectedStore,
      cities: undefined,
      darkStoreIds: undefined,
      productUrls: undefined,
    })

    fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    fetchStockOutTrends({ dateRange: defaultDateRange, storeId: selectedStore })
  }, [selectedStore])

  const transformedStockOutTrendsData = stockOutTrendsData?.map((item) => ({
    date: item?._id?.createdAt,
    outOfStockPercentage: Number(item?.outOfStockPercentage.toFixed(0)),
  }))

  const handleStoreChange = (value) => {
    setSelectedStore(value)
  }

  const handleFormValuesChange = (changedValues, allValues) => {
    if ('storeId' in changedValues) {
      setSelectedStore(changedValues.storeId)
    } else if ('cities' in changedValues) {
      // Reset darkStoreIds when cities change
      form.setFieldsValue({ darkStoreIds: undefined, productUrls: undefined })

      fetchStockOutTrendsFilterDetails({ ...allValues, darkStoreIds: undefined })
      fetchStockOutTrends({ ...allValues, darkStoreIds: undefined })
    } else if ('darkStoreIds' in changedValues) {
      // Reset darkStoreIds
      form.setFieldsValue({ productUrls: undefined })

      fetchStockOutTrendsFilterDetails({ ...allValues, productUrls: undefined })
      fetchStockOutTrends({ ...allValues, productUrls: undefined })
    } else {
      // Fetch data for other changes
      fetchStockOutTrendsFilterDetails(allValues)
      fetchStockOutTrends(allValues)
    }
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({
      dateRange: defaultDateRange,
      storeId: selectedStore,
    })
    fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    fetchStockOutTrends({ dateRange: defaultDateRange, storeId: selectedStore })
  }

  const handleApplyFilters = async (download = false) => {
    try {
      const values = await form.validateFields()
      console.log(values, download)
      fetchStockOutTrends(values, download)
    } catch (error) {
      console.error('Error in applying filters:', error)
    }
  }

  return (
    <div className='p-6'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Form
              form={form}
              layout='vertical'
              initialValues={{
                dateRange: defaultDateRange,
                storeId: selectedStore,
              }}
              onValuesChange={handleFormValuesChange}
              onFinish={handleApplyFilters}
            >
              <Row gutter={16}>
                <Col xs={14} sm={4}>
                  <Form.Item name='storeId' label=' Marketplace'>
                    <Select onChange={handleStoreChange} className='w-40'>
                      {stores.map((store) => (
                        <Option key={store} value={store}>
                          {store.charAt(0).toUpperCase() + store.slice(1)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={14} sm={4}>
                  <Form.Item
                    name='dateRange'
                    label='Date Range'
                    rules={[{ required: true, message: 'Please select date range' }]}
                  >
                    <RangePicker className='w-full' format='YYYY-MM-DD HH:mm:ss' />
                  </Form.Item>
                </Col>

                <Col xs={14} sm={4}>
                  <Form.Item name='cities' label='City' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select city' allowClear>
                      {filterData?.uniqueCities?.map((city) => (
                        <Option key={city} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={14} sm={12}>
                  <Form.Item
                    name='darkStoreIds'
                    label='Dark Store'
                    valuePropName='value'
                    normalize={(value) => (value ? [value] : [])}
                  >
                    <Select showSearch placeholder='Select darkstore ' allowClear optionFilterProp='children'>
                      {filterData?.uniqueDarkStores?.map((url) => (
                        <Option key={url.storeNameAddress} value={url.darkStoreId}>
                          {url.storeNameAddress}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col xs={14} sm={14}>
                  <Form.Item
                    name='productUrls'
                    label='Product Name'
                    valuePropName='value'
                    normalize={(value) => (value ? [value] : [])}
                  >
                    <Select showSearch placeholder='Select product ' allowClear optionFilterProp='children'>
                      {filterData?.uniqueProducts?.map((url) => (
                        <Option key={url.productUrl} value={url.productUrl}>
                          {url.productName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={14}
                  sm={10}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: '1rem',
                    marginTop: '20px',
                  }}
                >
                  <Form.Item>
                    <Space>
                      <Button onClick={handleReset}>Reset</Button>
                    </Space>
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button htmlType='submit'>Apply Filters</Button>
                    </Space>
                  </Form.Item>
                  <Form.Item>
                    {/* <Space>
                      <Button onClick={() => handleApplyFilters(true)}>Download</Button>
                    </Space> */}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card loading={loading}>
            <div style={{ height: 395 }}>
              <ResponsiveContainer>
                <LineChart
                  data={transformedStockOutTrendsData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 5,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='date'
                    label={{
                      value: 'Dates',
                      position: 'insideBottom',
                      offset: -10,
                    }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    label={{
                      value: 'Out of Stock (%)',
                      angle: -90,
                      position: 'insideLeft',
                      style: {
                        fontWeight: 600,
                        fontSize: 14,
                        fill: '#333',
                      },
                    }}
                  />
                  <Tooltip
                    formatter={(value, name, props) => {
                      // Only return the date value, not the percentage
                      return ['', props.payload.date]
                    }}
                    labelFormatter={(label) => ''}
                    contentStyle={{
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      border: '1px solid #ccc',
                      borderRadius: 4,
                      boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                    }}
                  />
                  <Line
                    type='monotone'
                    dataKey='outOfStockPercentage'
                    stroke='#1890ff'
                    strokeWidth={2}
                    name=' '
                    dot={{
                      r: 4,
                      stroke: '#1890ff',
                      strokeWidth: 2,
                      fill: 'white',
                    }}
                    activeDot={{
                      r: 6,
                      stroke: '#1890ff',
                      strokeWidth: 2,
                      fill: 'white',
                    }}
                    label={{
                      position: 'top',
                      fill: '#000',
                      fontSize: 14,
                      fontWeight: 'bold',
                      formatter: (value) => `${value}%`,
                      dx: 10,
                      dy: -8,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StockoutTrends
