import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Select, Card, Row, Col, Form, Space, Button, DatePicker, Progress, Spin } from 'gokwik-ui-kit'
import { getStockOutTrends, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { FilterData } from './types/types'
import { FormValues } from '@pages/TimewiseStockoutTrends/types/types'

const { Option } = Select
const { RangePicker } = DatePicker
const Inventory = () => {
  const [stockOutTrendsData, setStockOutTrendsData] = useState([])
  const [filterData, setFilterData] = useState<FilterData>({
    uniqueMerchantIds: [],
    uniqueStates: [],
    uniqueProducts: [],
    uniqueCities: [],
    uniqueDarkStores: [],
  })
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm<FormValues>()
  const [selectedStore, setSelectedStore] = useState('instamart')
  const [selectedMerchant, setSelectedMerchant] = useState('noise')
  const [downloadProgress, setDownloadProgress] = useState(0)

  const stores = ['instamart', 'zepto', 'blinkit']

  // Initialize default date range (2 days before today)
  const defaultDateRange = [dayjs().subtract(2, 'day'), dayjs()]
  const fetchStockOutTrends = async (values, download = false) => {
    setLoading(true)
    try {
      const dateRange = values.dateRange || defaultDateRange
      const formattedValues = {
        ...values,
        from: dateRange[0].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dateRange[1].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        storeId: selectedStore,
        downloadAll: download === true ? true : undefined,
      }
      delete formattedValues.dateRange // Remove dateRange from payload

      if (!download) {
        const response = await getStockOutTrends(formattedValues)
        setStockOutTrendsData(response?.products || [])
      } else {
        // Set initial progress to show the progress bar
        setDownloadProgress(1)

        const res = await fetch(`${process.env.BASE_URL}/topStockOutSKUs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
            'merchant-mid': localStorage.getItem('merchant-mid') || '',
          },
          credentials: 'include',
          body: JSON.stringify({ ...formattedValues, withCreds: true }),
        })

        // Check if we can get content length
        const contentLength = res.headers.get('Content-Length')

        if (contentLength) {
          // If Content-Length is available, use it for progress tracking
          const reader = res.body.getReader()
          let receivedLength = 0
          let chunks = []

          while (true) {
            const { done, value } = await reader.read()
            if (done) {
              setDownloadProgress(100) // Ensure 100% at completion
              break
            }

            chunks.push(value)
            receivedLength += value.length
            // Update progress, ensuring it's between 1 and 99 until complete
            const calculatedProgress = Math.min(
              99,
              Math.max(1, Math.floor((receivedLength / parseInt(contentLength)) * 100)),
            )
            setDownloadProgress(calculatedProgress)
          }

          const blob = new Blob(chunks, { type: 'text/csv;charset=utf-8' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'inventory_data.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        } else {
          setDownloadProgress(50)

          // Get the response as blob directly
          const blob = await res.blob()
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'inventory_data.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)

          setDownloadProgress(100) // Set to 100% when done
        }

        // Reset progress after a short delay to ensure the user sees completion
        setTimeout(() => {
          setDownloadProgress(0)
        }, 500)

        return
      }
    } catch (error) {
      console.error('Error in calling fetchStockOutTrends api', error)
      setDownloadProgress(0) // Reset on error
    } finally {
      setLoading(false)
    }
  }

  const fetchStockOutTrendsFilterDetails = async (formValues = form.getFieldsValue()) => {
    try {
      const payload = {
        ...(formValues.storeId && { storeIds: [formValues.storeId] }),
        ...(formValues.cities && { cities: formValues.cities }),
        ...(formValues.darkStoreIds && { darkStoreIds: formValues.darkStoreIds }),
      }
      const response = await getStockOutTrendsFilterDetails(payload)
      // Filter unique dark stores by pincode
      const uniqueDarkStores = Object.values(
        response.uniqueDarkStores.reduce((acc, store) => {
          acc[store.storeNamePincode] = store // Keep only the last occurrence
          return acc
        }, {}),
      )
      setFilterData({
        ...response,
        uniqueDarkStores,
      })
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Date Wise Inventory',
        href: '/kwikcomm/inventory',
        text: ' Date Wise Inventory',
      },
    ])

    // Set initial date range in form and fetch initial data
    form.setFieldsValue({
      dateRange: defaultDateRange,
      storeId: selectedStore,
    })
    // fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    // fetchStockOutTrends({ dateRange: defaultDateRange, storeId: selectedStore })
  }, [])

  // Effect for store changes
  useEffect(() => {
    console.log('select use effect')
    // Reset form fields except storeId
    form.setFieldsValue({
      dateRange: defaultDateRange,
      storeId: selectedStore,
      cities: undefined,
      darkStoreIds: undefined,
      productUrls: undefined,
    })

    fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    fetchStockOutTrends({ dateRange: defaultDateRange, storeId: selectedStore })
  }, [selectedStore])

  const transformedStockOutTrendsData = stockOutTrendsData?.map((item) => ({
    date: item?._id?.createdAt,
    inventory: Math.round(item?.inventory),
  }))

  const maxInventory = Math.max(...(transformedStockOutTrendsData.map((item) => item.inventory) || [0]))

  const getTicksArray = (maxValue) => {
    if (maxValue <= 100) {
      return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    } else if (maxValue <= 1000) {
      return [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
    } else if (maxValue <= 10000) {
      return [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]
    } else {
      return [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000]
    }
  }

  const handleStoreChange = (value) => {
    setSelectedStore(value)
  }

  const handleFormValuesChange = (changedValues, allValues) => {
    if ('storeId' in changedValues) {
      setSelectedStore(changedValues.storeId)
    } else if ('cities' in changedValues) {
      // Reset darkStoreIds when cities change
      form.setFieldsValue({ darkStoreIds: undefined, productUrls: undefined })

      fetchStockOutTrendsFilterDetails({ ...allValues, darkStoreIds: undefined, productUrls: undefined })
      fetchStockOutTrends({ ...allValues, darkStoreIds: undefined, productUrls: undefined })
    } else if ('darkStoreIds' in changedValues) {
      // Reset darkStoreIds
      form.setFieldsValue({ productUrls: undefined })

      fetchStockOutTrendsFilterDetails({ ...allValues, productUrls: undefined })
      fetchStockOutTrends({ ...allValues, productUrls: undefined })
    } else {
      // Fetch data for other changes
      fetchStockOutTrendsFilterDetails(allValues)
      fetchStockOutTrends(allValues)
    }
  }
  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({
      dateRange: defaultDateRange,
      storeId: selectedStore,
    })
    fetchStockOutTrendsFilterDetails({ storeId: selectedStore })
    fetchStockOutTrends({ dateRange: defaultDateRange, storeId: selectedStore })
  }

  const handleApplyFilters = async (download = false) => {
    try {
      const values = await form.validateFields()
      console.log(values, download)
      fetchStockOutTrends(values, download)
    } catch (error) {
      console.error('Error in applying filters:', error)
    }
  }
  return (
    <div className=''>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Form
              form={form}
              onFinish={handleApplyFilters}
              layout='vertical'
              initialValues={{
                dateRange: defaultDateRange,
              }}
              onValuesChange={handleFormValuesChange}
            >
              <Row gutter={16}>
                <Col xs={14} sm={4}>
                  <Form.Item
                    name='storeId'
                    initialValue={selectedStore}
                    label=' Marketplace'
                    // normalize={(value) => (value ? [value] : [])}
                  >
                    <Select onChange={handleStoreChange} className='w-40'>
                      {stores.map((store) => (
                        <Option key={store} value={store}>
                          {store.charAt(0).toUpperCase() + store.slice(1)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* Date Range Picker */}
                <Col xs={14} sm={4}>
                  <Form.Item
                    name='dateRange'
                    label='Date Range'
                    rules={[{ required: true, message: 'Please select date range' }]}
                  >
                    <RangePicker className='w-full' format='YYYY-MM-DD HH:mm:ss' />
                  </Form.Item>
                </Col>

                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='cities' label='City' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select city' allowClear optionFilterProp='children'>
                      {filterData?.uniqueCities?.map((city) => (
                        <Option key={city} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={14} sm={12}>
                  {' '}
                  <Form.Item
                    name='darkStoreIds'
                    label='Dark Store'
                    valuePropName='value'
                    normalize={(value) => (value ? [value] : [])}
                  >
                    <Select showSearch placeholder='Select darkstore ' allowClear optionFilterProp='children'>
                      {filterData?.uniqueDarkStores?.map((url) => (
                        <Option key={url.storeNameAddress} value={url.darkStoreId}>
                          {url.storeNameAddress}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={14} sm={16}>
                  {' '}
                  <Form.Item name='productUrls' label='Product Name ' normalize={(value) => (value ? [value] : [])}>
                    <Select showSearch placeholder='Select product ' allowClear optionFilterProp='children'>
                      {filterData?.uniqueProducts?.map((url) => (
                        <Option key={url.productUrl} value={url.productUrl}>
                          {url.productName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={14} sm={8} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                  <Form.Item>
                    <Space>
                      <Button type='primary' htmlType='submit'>
                        Apply Filters
                      </Button>
                      <Button onClick={handleReset}>Reset</Button>
                      <Button onClick={() => handleApplyFilters(true)} loading={loading} disabled={loading}>
                        Download All
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card>
            <div className='h-96'>
              <ResponsiveContainer>
                <LineChart
                  data={transformedStockOutTrendsData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 5,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='date'
                    label={{
                      value: 'Dates',
                      position: 'insideBottom',
                      offset: -10,
                    }}
                  />
                  <YAxis
                    domain={[0, getTicksArray(maxInventory)[10]]}
                    ticks={getTicksArray(maxInventory)}
                    label={{
                      value: 'Inventory',
                      angle: -90,
                      position: 'insideLeft',
                      style: {
                        fontWeight: 600,
                        fontSize: 14,
                        fill: '#333',
                      },
                    }}
                  />
                  <Tooltip />
                  <Line
                    type='monotone'
                    dataKey='inventory'
                    stroke='#1890ff'
                    dot={{
                      r: 4,
                      stroke: '#1890ff',
                      strokeWidth: 2,
                      fill: 'white',
                    }}
                    activeDot={{
                      r: 6,
                      stroke: '#1890ff',
                      strokeWidth: 2,
                      fill: 'white',
                    }}
                    label={{
                      position: 'top',
                      fill: '#000',
                      fontSize: 14,
                      fontWeight: 'bold',

                      dx: 10,
                      dy: -8,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
      {/* {downloadProgress > 0 && (
        <div
          style={{
            position: 'fixed',
            top: 20, // Distance from the top
            right: 20, // Distance from the right
            backgroundColor: '#fff', // White background
            padding: '16px 24px', // Padding for the pop-up
            borderRadius: 8, // Rounded corners
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // Subtle shadow
            zIndex: 1000, // Ensure it's above other elements
            display: 'flex',
            alignItems: 'center',
            gap: 16, // Space between text and progress bar
          }}
        >
          <span style={{ fontWeight: 500, color: '#333' }}>Downloading...</span>
          <Progress
            percent={downloadProgress}
            status='active'
            style={{ width: 150, margin: 0 }} // Smaller progress bar
          />
        </div>
      )} */}
    </div>
  )
}

export default Inventory
