import DarkStore from '@pages/DarkStore'
import Stockout from '@pages/Stockout'
import StockoutTrends from '@pages/TimewiseStockoutTrends'
import CitywiseStockoutTrends from '@pages/CitywiseStockoutTrends'
import storePincodeMapTable from '@pages/StorePincodeMapTable'
import TestPage from '@pages/Testpage'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import DarkStoreCount from '@pages/DarkStoreCount'
import InstamartUnlisted from '@pages/InstamartUnlisted'
import Inventory from '@pages/Inventory'
import CitywiseInventory from '@pages/CitywiseInventory'
import Reports from '@pages/Reports'

const AppRoutes = () => {
  const merchantDetails = useSelector(getMerchantDetails)
  if (!Object.keys(merchantDetails || {}).length) return <></>
  return (
    <Routes key={`${JSON.stringify(merchantDetails)}`}>
      <Route path='/kwikcomm/test' Component={TestPage} />
      <Route path='/kwikcomm/darkstore' Component={DarkStore} />
      <Route path='/kwikcomm/storePincodeMapTable' Component={storePincodeMapTable} />
      <Route path='/kwikcomm/stockout' Component={Stockout} />
      <Route path='/kwikcomm/datewise-stockout-trends' Component={StockoutTrends} />
      <Route path='/kwikcomm/citywise-stockout-trends' Component={CitywiseStockoutTrends} />
      <Route path='/kwikcomm/darkstore-count' Component={DarkStoreCount} />
      <Route path='/kwikcomm/unlisted' Component={InstamartUnlisted} />
      <Route path='/kwikcomm/inventory' Component={Inventory} />
      <Route path='/kwikcomm/citywise-inventory' Component={CitywiseInventory} />
      <Route path='/kwikcomm/reports' Component={Reports} />

      <Route
        path='*'
        element={
          <div className='m-auto'>
            <p className='text-center'>404 Not found</p>
          </div>
        }
      />
    </Routes>
  )
}

export default AppRoutes
