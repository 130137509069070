import React from 'react'

const TestPage = () => {
  // console.log(JSON.stringify(require('./test.json'), null, 2))

  return (
    <div>BMW m5 cs compition sport CS</div>
  )
}

export default TestPage