export const API_ENDPOINTS = {
  DARKSTORE_GROUP: '/getDarkStoresGroup',
  STOCKOUT_TABLE: '/topStockOutSKUs',
  STOCKPINCODEMAP_TABLE: '/storePincodeMap',
  STOCKOUT_TRENDS_FILTER: '/getStockOutTrendsFilter',
  GET_STOCKOUT_TRENDS: '/getStockOutTrends',
  GET_DARKSTORE_COUNT: '/darkStoreCount',
  INSTAMART_UNLISTED_BY_DARKSTORE: '/instamartUnlistedByDarkStore',
  INSTAMART_UNLISTED_BY_PRODUCTURL: '/instamartUnlistedByProductUrls',
}
