import React, { useEffect, useState } from 'react'
import { Select, Card, Row, Col, Form, Button, Space, DatePicker } from 'gokwik-ui-kit'
import { getStockOutTrendsFilterDetails, getDarkStoreCount } from '@library/api'
import dayjs from 'dayjs'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'

const { RangePicker } = DatePicker
const { Option } = Select

const defaultDateRange = [dayjs().startOf('day'), dayjs().endOf('day')]
interface FilterData {
  uniqueCities: string[]
  uniqueStates: string[]
  uniqueMerchantIds: string[]
  uniqueStoreIds: string[]
}

interface DarkStoreCountData {
  merchant: string
  storeId: string
  count: number
  state?: string
  city?: string
}

const DarkStoreCount: React.FC = () => {
  const merchantConfigs = useSelector(getMerchantDetails)
  const [form] = Form.useForm()
  const [filterData, setFilterData] = useState<FilterData | null>(null)
  const [countData, setCountData] = useState<DarkStoreCountData[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedState, setSelectedState] = useState('DELHI')
  console.log(filterData, 'the filter data')
  const merchantBuisnessName = merchantConfigs.business_name

  const fetchStockOutTrendsFilterDetails = async () => {
    try {
      const response = await getStockOutTrendsFilterDetails({ storeIds: ['zepto'] })
      console.log(response, 'the resone game of getStockOutTrendsFilterDetails ')
      setFilterData(response)
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  const fetchDarkStoreCounts = async (values: any) => {
    // if (!values.merchantId || !values.dateRange) return

    setLoading(true)
    try {
      const [startDate, endDate] = values.dateRange
      const formattedStartDate = startDate.format('YYYY-MM-DD 00:00:00')
      const formattedEndDate = endDate.format('YYYY-MM-DD 23:59:59')

      const storeIds = ['blinkit', 'instamart', 'zepto']
      const counts = await Promise.all(
        storeIds.map(async (storeId) => {
          const response = await getDarkStoreCount({
            merchantId: values.merchantId,
            from: formattedStartDate,
            to: formattedEndDate,
            states: values.state ? [values.state] : ['DELHI'],
            cities: values.city ? [values.city] : [],
            storeId: storeId,
          })

          return {
            merchant: values.merchantId,
            storeId: storeId,
            count: response?.data?.stores.length || 0,
            state: values.state ? values.state : 'DELHI',
            city: values.city,
          }
        }),
      )

      setCountData(counts)
    } catch (error) {
      console.error('Error fetching dark store counts:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Dark Store Count',
        href: '/kwikcomm/darkstore-count',
        text: 'kwikcomm Dark Store Count',
      },
    ])
    fetchStockOutTrendsFilterDetails()
    fetchDarkStoreCounts({
      dateRange: defaultDateRange,
      states: ['DELHI'],
    })
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <Card title='Dark Store Count' bordered={false}>
        <Form
          form={form}
          layout='vertical'
          onFinish={fetchDarkStoreCounts}
          initialValues={{
            dateRange: [dayjs().subtract(7, 'day'), dayjs()],
            merchantId: 'noise',
            state: 'DELHI',
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={4}>
              <Form.Item
                name='dateRange'
                label='Date Range'
                rules={[{ required: true, message: 'Please select date range' }]}
              >
                <RangePicker style={{ width: '100%' }} showTime format='YYYY-MM-DD HH:mm:ss' />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={4}>
              <Form.Item
                name='merchantId'
                label='Merchant'
                rules={[{ required: true, message: 'Please select merchant' }]}
              >
                <Select placeholder='Select Merchant'>
                  {filterData?.uniqueMerchantIds.map((merchant) => (
                    <Option key={merchant} value={merchant}>
                      {merchant}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={4}>
              <Form.Item name='state' label='State' rules={[{ required: true, message: 'Please select state' }]}>
                <Select placeholder='Select State'>
                  {filterData?.uniqueStates.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label=' ' style={{ marginTop: '29px' }}>
                <Button type='primary' htmlType='submit' loading={loading} block>
                  Apply Filters
                </Button>
              </Form.Item>
            </Col>
          </div>
        </Form>

        <Card style={{ marginTop: '24px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid #f0f0f0' }}>
                <th style={{ padding: '16px', textAlign: 'left' }}>Merchant</th>
                <th style={{ padding: '16px', textAlign: 'left' }}>Store ID</th>
                <th style={{ padding: '16px', textAlign: 'left' }}>Count</th>
                <th style={{ padding: '16px', textAlign: 'left' }}>State</th>
              </tr>
            </thead>
            <tbody>
              {countData.map((item, index) => {
                const currentMerchant = merchantBuisnessName
                return (
                  <tr key={index} style={{ borderBottom: '1px solid #f0f0f0' }}>
                    <td style={{ padding: '16px' }}>{currentMerchant}</td>
                    <td style={{ padding: '16px' }}>{item.storeId}</td>
                    <td style={{ padding: '16px' }}>{item.count}</td>
                    <td style={{ padding: '16px' }}>{item.state}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Card>
      </Card>
    </div>
  )
}

export default DarkStoreCount
