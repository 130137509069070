import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import './main.css'
import { Provider, useDispatch } from 'react-redux'
import store from './store'
// import ShipmentAnalytics from '@pages/Analytics'
// import Shipments from '@pages/Shipments'
import { dashboardStore } from '@gokwik/utilities'
import { setUserData } from '@store/user'

interface RouteType {
  path: string
  component: React.ComponentType
}

const InitialiseStore = ({ setIsHeaderHidden }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setUserData(dashboardStore.getState().userData))

    setIsHeaderHidden(
      dashboardStore.getState().userData?.merchantDetails
        ? dashboardStore.getState().userData?.merchantDetails?.onboarding_status !== 'setup_completed'
        : false,
    )
    const unsubscribe = dashboardStore.subscribe((state) => {
      dispatch(setUserData(state.userData))

      setIsHeaderHidden(
        state.userData?.merchant_details?.onboarding_status
          ? state.userData?.merchant_details?.onboarding_status !== 'setup_completed'
          : false,
      )
    })
    return () => unsubscribe?.()
  }, [])
  return <></>
}

const Root: React.FC = () => {
  const [sideNavWidth, setSideNavWidth] = useState<number>(0)
  const [isHeaderHidden, setIsHeaderHidden] = useState(false)
  const appContainerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    setTimeout(() => {
      const sideNav = document.getElementById('side-nav')
      if (sideNav) {
        setSideNavWidth(sideNav.offsetWidth)
      }
    }, 100)
  }, [])

  return (
    <Provider store={store}>
      <div
        ref={appContainerRef}
        className={`app-container h-screen bg-[#003c7138]  max-h-screen overflow-y-auto ${
          isHeaderHidden ? 'pt-20' : 'pt-20'
        } px-5`}
        style={{
          maxWidth: `calc(100vw - ${document.getElementById('side-nav')?.offsetWidth}px)`,
        }}
      >
        <InitialiseStore setIsHeaderHidden={setIsHeaderHidden} />

        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </Provider>
  )
}

export default Root
