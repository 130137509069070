import React, { useEffect, useState } from 'react'
import { Table, Input, Button, Select, Form, Row, Col, Card } from 'gokwik-ui-kit'
import { ReloadOutlined } from 'gokwik-ui-kit'
import { getDarkStoresGroup } from '@library/api'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { updateBreadcrumbs } from '@gokwik/utilities'

interface StoreData {
  darkStoredMap: Record<string, { darkStoreCount: number }>
  totalCount: number
}

type GroupBy = 'city' | 'state'

const { Option } = Select

const DarkStore: React.FC = () => {
  const [form] = Form.useForm()
  const [storeData, setStoreData] = useState<{
    zepto: StoreData
    instamart: StoreData
    blinkit: StoreData
  }>({
    zepto: { darkStoredMap: {}, totalCount: 0 },
    instamart: { darkStoredMap: {}, totalCount: 0 },
    blinkit: { darkStoredMap: {}, totalCount: 0 },
  })
  const [loading, setLoading] = useState(false)
  const [groupBy, setGroupBy] = useState<GroupBy>('city')

  const fetchData = async (values?: any) => {
    setLoading(true)
    try {
      const [instamart, zepto, blinkit] = await Promise.all([
        getDarkStoresGroup({ storeId: 'instamart', groupBy, ...values }),
        getDarkStoresGroup({ storeId: 'zepto', groupBy, ...values }),
        getDarkStoresGroup({ storeId: 'blinkit', groupBy, ...values }),
      ])

      console.log(instamart, 'check the response of instamart')
      setStoreData({
        instamart,
        zepto,
        blinkit,
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Darkstore',
        href: '/kwikcomm/darkstore',
        text: ' Darkstore',
      },
    ])
    fetchData()
  }, [groupBy])

  const getAllLocations = () => {
    const locations = new Set([
      ...Object.keys(storeData.zepto?.darkStoredMap || {}),
      ...Object.keys(storeData.instamart?.darkStoredMap || {}),
      ...Object.keys(storeData.blinkit?.darkStoredMap || {}),
    ])
    return Array.from(locations)
  }

  const columns = [
    {
      title: groupBy === 'city' ? 'City' : 'State',
      dataIndex: 'location',
      key: 'location',
      sorter: (a: any, b: any) => a.location.localeCompare(b.location),
    },
    {
      title: 'Zepto',
      dataIndex: 'zepto',
      key: 'zepto',
      sorter: (a: any, b: any) => a.zepto - b.zepto,
      render: (value: number) => <span style={{ color: '#1890ff' }}>{value}</span>,
    },
    {
      title: 'Instamart',
      dataIndex: 'instamart',
      key: 'instamart',
      sorter: (a: any, b: any) => a.instamart - b.instamart,
      render: (value: number) => <span style={{ color: '#52c41a' }}>{value}</span>,
    },
    {
      title: 'Blinkit',
      dataIndex: 'blinkit',
      key: 'blinkit',
      sorter: (a: any, b: any) => a.blinkit - b.blinkit,
      render: (value: number) => <span style={{ color: '#fa8c16' }}>{value}</span>,
    },
  ]

  const dataSource = getAllLocations().map((location) => ({
    key: location,
    location,
    zepto: storeData.zepto?.darkStoredMap?.[location]?.darkStoreCount || 0,
    instamart: storeData.instamart?.darkStoredMap?.[location]?.darkStoreCount || 0,
    blinkit: storeData.blinkit?.darkStoredMap?.[location]?.darkStoreCount || 0,
  }))

  return (
    <div style={{ width: '100%' }}>
      {/* Filter Section */}
      <Card style={{ marginBottom: '24px' }}>
        <Form form={form} layout='vertical' onFinish={fetchData}>
          <Row gutter={[16, 16]}>
            {/* <Col xs={24} sm={12} md={6}>
              <Form.Item name='storeId' label='Store'>
                <Select allowClear placeholder='Select store'>
                  <Option value='zepto'>Zepto</Option>
                  <Option value='instamart'>Instamart</Option>
                  <Option value='blinkit'>Blinkit</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={12} md={6}>
              <Form.Item name='cities' label='Cities'>
                <Select
                  mode='multiple'
                  allowClear
                  placeholder='Select cities'
                  maxTagCount='responsive'
                  disabled={groupBy === 'state'}
                >
                  {getAllLocations().map((location) => (
                    <Option key={location} value={location}>
                      {location}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item label='Group By'>
                <Select value={groupBy} onChange={setGroupBy} placeholder='Select grouping'>
                  <Option value='city'>City</Option>
                  <Option value='state'>State</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button type='primary' htmlType='submit' style={{ marginRight: '8px' }}>
                Apply Filters
              </Button>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => {
                  form.resetFields()
                  fetchData()
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      {/* Table Section */}
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: 800, y: 400 }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} ${groupBy === 'city' ? 'cities' : 'states'}`,
        }}
        summary={(props) => (
          <tr style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
            <td>
              <strong>Total Stores</strong>
            </td>
            <td>
              <strong style={{ color: '#1890ff' }}>{storeData.zepto?.totalCount}</strong>
            </td>
            <td>
              <strong style={{ color: '#52c41a' }}>{storeData.instamart?.totalCount}</strong>
            </td>
            <td>
              <strong style={{ color: '#fa8c16' }}>{storeData.blinkit?.totalCount}</strong>
            </td>
          </tr>
        )}
      />
    </div>
  )
}

export default DarkStore
