import React, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Select, Card, Row, Col, Form, Button, Space, DatePicker } from 'gokwik-ui-kit'
import { getStockOutTrends, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { FilterData } from './types/types'

const { Option } = Select
const { RangePicker } = DatePicker

const StockoutTrends = () => {
  const [stockOutTrendsData, setStockOutTrendsData] = useState([])
  const [filterData, setFilterData] = useState<FilterData>({
    uniqueMerchantIds: [],
    uniqueStates: [],
    uniqueProducts: [],
  })
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  const [selectedStore, setSelectedStore] = useState('zepto')
  const [selectedMerchant, setSelectedMerchant] = useState('noise')

  const stores = ['instamart', 'zepto', 'blinkit']

  // Initialize default date range (2 days before today)
  const defaultDateRange = [dayjs().subtract(2, 'day'), dayjs()]

  const fetchStockOutTrends = async (values) => {
    setLoading(true)
    try {
      // Format dates for API
      const dateRange = values.dateRange || defaultDateRange
      const formattedValues = {
        ...values,
        from: dateRange[0].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dateRange[1].format('YYYY-MM-DDTHH:mm:ss.SSS'),
        storeId: selectedStore,
      }

      delete formattedValues.dateRange // Remove dateRange from payload

      const response = await getStockOutTrends(formattedValues)
      setStockOutTrendsData(response?.products || [])
    } catch (error) {
      console.error('Error in calling fetchStockOutTrends api', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStockOutTrendsFilterDetails = async (storeId) => {
    try {
      const response = await getStockOutTrendsFilterDetails({
        storeIds: [storeId],
        // merchantIds: [selectedMerchant],
      })
      setFilterData(response)
    } catch (error) {
      console.error('Error in calling getStockOutTrendsFilter api', error)
    }
  }

  useEffect(() => {
    fetchStockOutTrendsFilterDetails(selectedStore)
    // Set initial date range in form
    form.setFieldsValue({ dateRange: defaultDateRange })
    fetchStockOutTrends({ dateRange: defaultDateRange })
  }, [selectedStore])

  const transformedStockOutTrendsData = stockOutTrendsData?.map((item) => ({
    date: item?._id?.createdAt,
    outOfStockPercentage: Number(item?.outOfStockPercentage?.toFixed(2)),
  }))

  const handleStoreChange = (value) => {
    setSelectedStore(value)
  }

  const handleSelectedMerchant = (value) => {
    setSelectedMerchant(value)
  }

  const onFinish = (values) => {
    fetchStockOutTrendsFilterDetails(selectedStore)
    fetchStockOutTrends(values)
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({ dateRange: defaultDateRange })
    fetchStockOutTrends({ dateRange: defaultDateRange })
  }

  return (
    <div className='p-6'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <div className='flex justify-between items-center mb-6'>
              <h1 className='text-2xl font-semibold m-0'>Product Stock Availability</h1>
              <Select value={selectedStore} onChange={handleStoreChange} className='w-40'>
                {stores.map((store) => (
                  <Option key={store} value={store}>
                    {store.charAt(0).toUpperCase() + store.slice(1)}
                  </Option>
                ))}
              </Select>
            </div>

            <Form
              form={form}
              onFinish={onFinish}
              layout='vertical'
              initialValues={{
                dateRange: defaultDateRange,
              }}
            >
              <Row gutter={16}>
                {/* Date Range Picker */}
                <Col xs={14} sm={4}>
                  <Form.Item
                    name='dateRange'
                    label='Date Range'
                    rules={[{ required: true, message: 'Please select date range' }]}
                  >
                    <RangePicker className='w-full' showTime format='YYYY-MM-DD HH:mm:ss' />
                  </Form.Item>
                </Col>

                {/* <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='merchantId' label='Merchant ID'>
                    <Select
                      showSearch
                      placeholder='Select merchant ID'
                      allowClear
                      optionFilterProp='children'
                      onChange={handleSelectedMerchant}
                    >
                      {filterData?.uniqueMerchantIds?.map((id) => (
                        <Option key={id} value={id}>
                          {id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item name='cities' label='City'>
                    <Select showSearch placeholder='Select city' allowClear mode='multiple'>
                      {filterData?.uniqueCities?.map((city) => (
                        <Option key={city} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item
                    name='productUrls'
                    label='Product URLs'
                    valuePropName='value'
                    normalize={(value) => (value ? [value] : [])}
                  >
                    <Select showSearch placeholder='Select product URLs' allowClear optionFilterProp='children'>
                      {filterData?.uniqueProducts?.map((url) => (
                        <Option key={url.productUrl} value={url.productUrl}>
                          {url.productName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={14} sm={4}>
                  {' '}
                  <Form.Item
                    name='darkstoreIds'
                    label='Dark store Ids'
                    valuePropName='value'
                    normalize={(value) => (value ? [value] : [])}
                  >
                    <Select showSearch placeholder='Select darkstore Ids' allowClear optionFilterProp='children'>
                      {filterData?.uniqueDarkStores?.map((url) => (
                        <Option key={url.storeNameAddress} value={url.storeNamePincode}>
                          {url.storeNameAddress}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Space>
                      <Button type='primary' htmlType='submit'>
                        Apply Filters
                      </Button>
                      <Button onClick={handleReset}>Reset</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card loading={loading}>
            <div style={{ height: 395 }}>
              <ResponsiveContainer>
                <LineChart
                  data={transformedStockOutTrendsData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: 5,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='date'
                    label={{
                      value: 'Dates',
                      position: 'insideBottom',
                      offset: -10,
                    }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    label={{
                      value: 'Out of Stock (%)',
                      angle: -90,
                      position: 'insideLeft',
                    }}
                  />
                  <Tooltip />
                  <Line type='monotone' dataKey='outOfStockPercentage' stroke='#1890ff' name='%' dot={true} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StockoutTrends
