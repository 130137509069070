import React, { useState, useEffect } from 'react'
import { Select, Card, Row, Col, Form, Space, Button, DatePicker, Input, Table, ColumnsType } from 'gokwik-ui-kit'
import { getStockoutTableData, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { CaretDownOutlined, CaretRightOutlined } from 'gokwik-ui-kit'
import { updateBreadcrumbs } from '@gokwik/utilities'
import { FormValues, ParamsValue } from '@pages/TimewiseStockoutTrends/types/types'
const { Option } = Select

const ITEMS_PER_PAGE = 10
const CITIES_PER_PAGE = 4

interface StockDataType {
  productName: string
  outOfStockPercentage: string
  key: string
}

const StockoutTable = () => {
  // State management - maintaining original component's structure
  const [stockData, setStockData] = useState([])
  const [selectedStore, setSelectedStore] = useState('instamart')
  const [currentPage, setCurrentPage] = useState(1)
  const [expandedProducts, setExpandedProducts] = useState({})
  const [cityPages, setCityPages] = useState({})
  const [loading, setLoading] = useState({ filter: false, download: false })

  // Filter-related states
  const [filterData, setFilterData] = useState({
    uniqueCities: [],
    uniqueMerchantIds: [],
    uniqueDarkStores: [],
    uniqueProducts: [],
  })
  const [selectedCity, setSelectedCity] = useState('')
  const [dateRange, setDateRange] = useState(null)
  const [form] = Form.useForm()

  const stores = ['instamart', 'zepto', 'blinkit']

  // Reset form fields based on which field was changed// Reset form fields based on which field was changed
  const resetFormFields = (fieldName, value) => {
    const currentValues = form.getFieldsValue()
    console.log(currentValues, 'the reset feilds game')

    if (fieldName === 'storeId') {
      // Reset all filters except storeId when marketplace changes
      form.resetFields(['cities', 'darkStoreIds', 'productUrls', 'date'])
      form.setFieldsValue({ storeId: value })
      setSelectedCity('')
      setDateRange(null)
    } else if (fieldName === 'cities') {
      // Reset product URLs and darkStoreIds when city changes
      form.resetFields(['darkStoreIds', 'productUrls'])
      form.setFieldsValue({ ...currentValues, cities: value, darkStoreIds: undefined, productUrls: undefined })
    } else if (fieldName === 'darkStoreIds') {
      form.resetFields(['productUrls'])
      form.setFieldsValue({ ...currentValues, darkStoreIds: value, productUrls: undefined })
    }
  }

  // Fetch filter details
  const fetchFilterDetails = async (formValues: FormValues = {}) => {
    try {
      const payload = {
        ...(formValues.storeId && { storeIds: [formValues.storeId] }),
        ...(formValues.cities && formValues.cities.length > 0 && { cities: formValues.cities }),
        ...(formValues.darkStoreIds && formValues.darkStoreIds.length > 0 && { darkStoreIds: formValues.darkStoreIds }),
        ...(formValues.productUrls && formValues.productUrls.length > 0 && { productUrls: formValues.productUrls }),
      }

      const response = await getStockOutTrendsFilterDetails(payload)
      // Filter unique dark stores by pincode
      const uniqueDarkStores = Object.values(
        response.uniqueDarkStores.reduce((acc, store) => {
          acc[store.storeNamePincode] = store // Keep only the last occurrence
          return acc
        }, {}),
      )
      setFilterData({
        ...response,
        uniqueDarkStores,
      })

      // Auto-fetch data if all required filters are present
      if (formValues.storeId) {
        fetchStockoutData({
          storeId: formValues.storeId,
          ...(formValues.cities && formValues.cities.length > 0 && { cities: formValues.cities }),
          ...(formValues.darkStoreIds &&
            formValues.darkStoreIds.length > 0 && { darkStoreIds: formValues.darkStoreIds }),
          ...(formValues.productUrls && formValues.productUrls.length > 0 && { productUrls: formValues.productUrls }),
          ...(formValues.date && {
            from: dayjs(formValues.date)
              .subtract(1, 'day')
              .hour(18)
              .minute(30)
              .second(0)
              .format('YYYY-MM-DDTHH:mm:ss.SSS'),
            to: dayjs(formValues.date).hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
          }),
          ...(!formValues.date && {
            from: dayjs().subtract(1, 'day').hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
            to: dayjs().hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
          }),
        })
      }
    } catch (error) {
      console.error('Error fetching filter details:', error)
    }
  }

  // Fetch stockout data with filters
  const fetchStockoutData = async (params: ParamsValue = {}) => {
    console.log(params, 'the params game')
    try {
      setLoading({ ...loading, filter: true })

      console.log(params.downloadAll, 'checking the flag')
      if (params.downloadAll) {
        // Use fetch directly with proper headers for download
        const res = await fetch(`${process.env.BASE_URL}/topStockOutSKUs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
            'merchant-mid': localStorage.getItem('merchant-mid') || '',
          },
          credentials: 'include',
          body: JSON.stringify({ ...params, withCreds: true }),
        })

        // Get the response as text for CSV
        const csvText = await res.text()

        // Create blob from text with correct MIME type
        const blob = new Blob([csvText], { type: 'text/csv;charset=utf-8' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'stockout_data.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)

        setLoading({ ...loading, filter: false })
        return
      }

      // For normal data display (not download)
      const response = await getStockoutTableData(params)

      // Transform the data for the table
      const transformedData =
        response?.products?.map((product, index) => {
          // Calculate average out of stock percentage across all stores
          const totalOutOfStock = product.storeData.reduce((acc, store) => {
            const outOfStockPercent = (store.unavailable / (store.available + store.unavailable)) * 100
            return acc + outOfStockPercent
          }, 0)

          const averageOutOfStock = (totalOutOfStock / product.storeData.length).toFixed(2)

          return {
            key: index,
            productName: product.productName,
            outOfStockPercentage: `${averageOutOfStock}%`,
          }
        }) || []

      setStockData(transformedData)
      setCurrentPage(1)
      setExpandedProducts({})
      setCityPages({})
    } catch (error) {
      console.error('Error fetching stockout data:', error)
    } finally {
      setLoading({ ...loading, filter: false })
    }
  }

  // Handle Download Button Click
  const handleDownload = () => {
    const formValues = form.getFieldsValue()
    const payload = {
      ...(formValues.storeId && { storeId: formValues.storeId }),
      ...(formValues.states && { states: formValues.states }),
      ...(formValues.darkStoreIds && { darkStoreIds: formValues.darkStoreIds }),
      downloadAll: true,
      ...(formValues.date && {
        from: dayjs(formValues.date).subtract(1, 'day').hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dayjs(formValues.date).hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      }),
      ...(!formValues.date && {
        from: dayjs().subtract(1, 'day').hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dayjs().hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      }),
    }
    fetchStockoutData(payload)
  }

  // Initial data fetch
  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm stockout',
        href: 'kwikcomm/stockout',
        text: 'Stockout',
      },
    ])
    fetchFilterDetails({ storeId: selectedStore })
  }, [])

  // Handle marketplace change
  const handleMarketplaceChange = (value) => {
    setSelectedStore(value)
    resetFormFields('storeId', value)
    fetchFilterDetails({ storeId: value })
  }

  // Handle city change
  const handleCityChange = (value) => {
    setSelectedCity(value)
    const cityArray = value ? [value] : []
    resetFormFields('cities', cityArray)

    const currentValues = form.getFieldsValue()
    const updatedValues = {
      ...currentValues,
      cities: cityArray,
      darkStoreIds: undefined,
      productUrls: undefined,
    }

    form.setFieldsValue(updatedValues)
    fetchFilterDetails(updatedValues)
  }

  // Handle filter change for auto-filtering
  const handleFilterChange = (field, value) => {
    if (field === 'storeId') {
      handleMarketplaceChange(value)
      return
    } else if (field === 'cities') {
      handleCityChange(value)
      return
    } else if (field === 'darkStoreIds') {
      // Clear product selection when darkstore changes
      resetFormFields('darkStoreIds', value)
      const currentValues = form.getFieldsValue()
      const darkStoreArray = value ? [value] : []
      const updatedValues = {
        ...currentValues,
        darkStoreIds: darkStoreArray,
        productUrls: undefined,
      }
      form.setFieldsValue(updatedValues)
      fetchFilterDetails(updatedValues)
      return
    }

    const currentValues = form.getFieldsValue()
    const updatedValues = { ...currentValues, [field]: value }
    form.setFieldsValue(updatedValues)

    // Apply auto-filtering
    fetchFilterDetails(updatedValues)
  }

  const handleReset = () => {
    form.resetFields()
    fetchFilterDetails({ storeId: selectedStore })
  }
  // Filter submission handler (for Apply Filters button)
  const handleFilterSubmit = async (values: any, isDownload: boolean = false) => {
    const loadingKey = isDownload ? 'download' : 'filter'
    setLoading({ ...loading, [loadingKey]: true })

    try {
      fetchFilterDetails(values)
    } catch (error) {
      console.error('Error in form submission:', error)
    } finally {
      setLoading({ ...loading, [loadingKey]: false })
    }
  }

  // Define the columns array
  const columns: ColumnsType = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      render: (text: string) => (
        <a href={text} target='_blank' rel='noopener noreferrer'>
          {text}
        </a>
      ),
    },
    {
      title: 'Out of Stock Percentage',
      dataIndex: 'outOfStockPercentage',
      key: 'outOfStockPercentage',
      align: 'right',
      sorter: (a: StockDataType, b: StockDataType) => {
        const aValue = parseFloat(a.outOfStockPercentage)
        const bValue = parseFloat(b.outOfStockPercentage)
        return aValue - bValue
      },
    },
  ]

  return (
    <Space direction='vertical' className='w-full' size='large'>
      {/* Comprehensive Filter Section */}
      <Card className='w-full'>
        <Form form={form} onFinish={handleFilterSubmit} layout='vertical' initialValues={{ storeId: selectedStore }}>
          <Row gutter={16}>
            {/* Store Selector */}
            <Col xs={14} sm={4}>
              <Form.Item name='storeId' initialValue={selectedStore} label=' Marketplace'>
                <Select className='w-40' onChange={(value) => handleFilterChange('storeId', value)}>
                  {stores.map((store) => (
                    <Option key={store} value={store}>
                      {store.charAt(0).toUpperCase() + store.slice(1)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* Date Range Picker */}
            <Col xs={14} sm={4}>
              <Form.Item name='date' label='Date'>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(date) => handleFilterChange('date', date)}
                  disabledDate={(current) => current && current > dayjs().endOf('day')}
                />
              </Form.Item>
            </Col>
            {/* City Selector */}
            <Col xs={14} sm={5}>
              <Form.Item name='cities' label=' City' normalize={(value) => (value ? [value] : [])}>
                <Select
                  placeholder='Select City'
                  showSearch
                  onChange={(value) => handleFilterChange('cities', value)}
                  allowClear
                >
                  {filterData.uniqueCities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={14} sm={10}>
              <Form.Item
                name='darkStoreIds'
                label='Dark Store'
                valuePropName='value'
                normalize={(value) => (value ? [value] : [])}
              >
                <Select
                  showSearch
                  placeholder='Select darkstore'
                  allowClear
                  optionFilterProp='children'
                  // onChange={(value) => handleFilterChange('darkStoreIds', value ? [value] : [])}
                >
                  {filterData?.uniqueDarkStores?.map((url) => (
                    <Option key={url.storeNameAddress} value={url.darkStoreId}>
                      {url.storeNameAddress}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {/* Product Selector  */}
            <Col xs={14} sm={20}>
              <Form.Item name='productUrls' label='Product Name' normalize={(value) => (value ? [value] : [])}>
                <Select
                  placeholder='Select Product'
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  onChange={(value) => handleFilterChange('productUrls', value ? [value] : [])}
                >
                  {filterData?.uniqueProducts?.map((product) => (
                    <Option key={product.productUrl} value={product.productUrl}>
                      {product.productName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* Filter Button */}
            <Col span={12} style={{ display: 'flex', gap: '10px' }}>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='mt-4'>
                  Apply Filters
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={handleReset} className='mt-4'>
                  Reset
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType='button'
                  className='mt-4'
                  onClick={handleDownload}
                  loading={loading.filter}
                  disabled={loading.filter}
                >
                  Download All
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card className='w-full'>
        <Table
          columns={columns}
          dataSource={stockData}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          loading={loading.filter}
        />
      </Card>
    </Space>
  )
}

export default StockoutTable
