import React, { useState, useEffect } from 'react'
import { Select, Card, Row, Col, Form, Space, Button, DatePicker, Input } from 'gokwik-ui-kit'
import { getStockoutTableData, getStockOutTrendsFilterDetails } from '@library/api'
import dayjs from 'dayjs'
import { CaretDownOutlined, CaretRightOutlined } from 'gokwik-ui-kit'
import { updateBreadcrumbs } from '@gokwik/utilities'
const { Option } = Select
// const { RangePicker } = DatePicker

const ITEMS_PER_PAGE = 10
const CITIES_PER_PAGE = 4

const StockoutTable = () => {
  // State management - maintaining original component's structure
  const [stockData, setStockData] = useState([])
  const [selectedStore, setSelectedStore] = useState('instamart')
  const [currentPage, setCurrentPage] = useState(1)
  const [expandedProducts, setExpandedProducts] = useState({})
  const [cityPages, setCityPages] = useState({})
  const [loading, setLoading] = useState({ filter: false, download: false })

  // Filter-related states
  const [filterData, setFilterData] = useState({
    uniqueCities: [],
    uniqueMerchantIds: [],
    uniqueDarkStores: [],
    uniqueProducts: [],
  })
  // console.log(filterData, 'the real game')
  const [selectedMerchantId, setSelectedMerchantId] = useState('noise')
  const [selectedCity, setSelectedCity] = useState('AGRA')
  const [dateRange, setDateRange] = useState(null)
  const [form] = Form.useForm()

  const stores = ['instamart', 'zepto', 'blinkit']

  // Fetch filter details
  const fetchFilterDetails = async () => {
    try {
      const response = await getStockOutTrendsFilterDetails({
        storeIds: [selectedStore],
        // merchantIds: [selectedMerchantId],
      })

      console.log(response, 'the response game')
      setFilterData({
        uniqueCities: response?.uniqueCities || [],
        uniqueMerchantIds: response?.uniqueMerchantIds || [],
        uniqueDarkStores: response?.uniqueDarkStores || [],
        uniqueProducts: response?.uniqueProducts,
      })
    } catch (error) {
      console.error('Error fetching filter details:', error)
    }
  }

  // Fetch stockout data with filters
  const fetchStockoutData = async (params = {}) => {
    try {
      const filterParams = {
        storeId: selectedStore,
        merchantId: selectedMerchantId,
        // productUrls: selectedProductUrls,
        cities: [selectedCity],
        ...params,
      }

      const response = await getStockoutTableData(filterParams)

      setStockData(response?.products || [])
      setCurrentPage(1)
      setExpandedProducts({})
      setCityPages({})
    } catch (error) {
      console.error('Error fetching stockout data:', error)
    }
  }

  // Initial data fetches
  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm stockout',
        href: 'kwikcomm/stockout',
        text: 'kwik Comm Stockout',
      },
    ])
    fetchFilterDetails()
    fetchStockoutData({
      from: dayjs().subtract(1, 'day').hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      to: dayjs().hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    })
  }, [selectedStore])

  // Pagination and data display logic
  const totalPages = Math.ceil(stockData.length / ITEMS_PER_PAGE)
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE
  const currentProducts = stockData.slice(startIndex, endIndex)

  // Product expansion and city pagination handlers
  const toggleProduct = (productId) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }))

    if (!expandedProducts[productId]) {
      setCityPages((prev) => ({
        ...prev,
        [productId]: 1,
      }))
    }
  }

  const getCityPage = (productId, cityData) => {
    const currentCityPage = cityPages[productId] || 1
    const cityStartIndex = (currentCityPage - 1) * CITIES_PER_PAGE
    const cityEndIndex = cityStartIndex + CITIES_PER_PAGE
    return {
      cities: cityData.slice(cityStartIndex, cityEndIndex),
      totalPages: Math.ceil(cityData.length / CITIES_PER_PAGE),
      currentPage: currentCityPage,
    }
  }

  // Filter submission handler
  const handleFilterSubmit = async (values: any, isDownload: boolean = false) => {
    const loadingKey = isDownload ? 'download' : 'filter'
    setLoading({ ...loading, [loadingKey]: true })

    try {
      fetchFilterDetails()

      const selectedDate = values.date
      const fromDate = dayjs(selectedDate).subtract(1, 'day')

      const payload = {
        productUrls: values.productUrls,
        city: values.city,
        from: dateRange
          ? fromDate.hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS')
          : dayjs().subtract(1, 'day').hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        to: dateRange
          ? selectedDate.hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS')
          : dayjs().hour(18).minute(30).second(0).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      }
      delete payload.city
      // if (isDownload) {
      //   payload.download = true
      // }

      const response = await fetchStockoutData(payload)

      // console.log(isDownload, 'check the flag')
      // console.log(response, 'check the response')
      // if (isDownload && response?.data) {
      //   // Handle file download
      //   const blob = new Blob([response.data], { type: 'application/octet-stream' })
      //   const url = window.URL.createObjectURL(blob)
      //   const link = document.createElement('a')
      //   link.href = url
      //   link.setAttribute('download', `stockout-data-${dayjs().format('YYYY-MM-DD')}.csv`)
      //   document.body.appendChild(link)
      //   link.click()
      //   link.remove()
      //   window.URL.revokeObjectURL(url)
      // }
    } catch (error) {
      console.error('Error in form submission:', error)
    } finally {
      setLoading({ ...loading, [loadingKey]: false })
    }
  }

  return (
    <Space direction='vertical' className='w-full' size='large'>
      {/* Comprehensive Filter Section */}
      <Card className='w-full'>
        <Form
          form={form}
          onFinish={handleFilterSubmit}
          layout='vertical'
          initialValues={{ storeId: selectedStore, city: selectedCity }}
        >
          <Row gutter={16}>
            {/* Store Selector */}
            <Col span={6}>
              <Form.Item name='storeId' label='Select Store'>
                <Select value={selectedStore} onChange={setSelectedStore}>
                  {stores.map((store) => (
                    <Option key={store} value={store}>
                      {store.charAt(0).toUpperCase() + store.slice(1)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* City Selector */}
            <Col span={6}>
              <Form.Item name='city' label='Select City' normalize={(value) => (value ? [value] : [])}>
                <Select placeholder='Select City' value={selectedCity} onChange={setSelectedCity}>
                  {filterData.uniqueCities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Product Selector  */}
            <Col span={6}>
              <Form.Item name='productUrls' label='Select Product' normalize={(value) => (value ? [value] : [])}>
                <Select placeholder='Select Product' allowClear optionFilterProp='children'>
                  {filterData?.uniqueProducts.map((product) => (
                    <Option key={product.productUrl} value={product.productUrl}>
                      {product.productName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Date Range Picker */}
            <Col span={6}>
              <Form.Item name='date' label='Date'>
                <DatePicker style={{ width: '100%' }} onChange={(dates) => setDateRange(dates)} />
              </Form.Item>
            </Col>

            {/* Filter Button */}
            <Col span={12}>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='mt-4'>
                  Apply Filters
                </Button>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item>
                <Button
                  type='primary'
                  loading={loading.download}
                  className='mt-4'
                  onClick={() => {
                    const values = form.getFieldsValue()
                    handleFilterSubmit(values, true)
                  }}
                >
                  Download
                </Button>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Card>

      {/* Products Section */}
      <Card title='Product Stock Availability' className='w-full'>
        {currentProducts.map((product, index) => {
          console.log(currentProducts, 'the current products real game')
          const productId = `${index}-${startIndex}`
          const isExpanded = expandedProducts[productId]
          const {
            cities,
            totalPages: cityTotalPages,
            currentPage: cityCurrentPage,
          } = getCityPage(productId, product.storeData)

          return (
            <Card
              key={productId}
              className='mb-4'
              type='inner'
              title={
                <div className='flex items-center cursor-pointer' onClick={() => toggleProduct(productId)}>
                  {isExpanded ? <CaretDownOutlined className='mr-2' /> : <CaretRightOutlined className='mr-2' />}
                  <a
                    href={product.productName}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => e.stopPropagation()}
                  >
                    {product.productName}
                  </a>
                </div>
              }
            >
              {isExpanded && (
                <Row>
                  <Col span={24}>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <th className='p-2 text-left'>City</th>
                          <th className='p-2 text-left'>Stock Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cities.map((item, idx) => {
                          console.log(item, 'the real item ame of city')
                          const outOfStockPercent = (
                            (item?.unavailable / (item?.available + item?.unavailable)) *
                            100
                          ).toFixed(2)
                          // let pick the current city which is selected
                          const currentSelectedCity = selectedCity
                          return (
                            <tr key={idx}>
                              <td className='p-2'>{currentSelectedCity}</td>
                              <td className='p-2'>
                                <div className='flex items-center'>
                                  <span>{outOfStockPercent}%</span>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>

                    {cityTotalPages > 1 && (
                      <div className='flex justify-center mt-4 gap-4'>
                        <Button
                          disabled={cityCurrentPage === 1}
                          onClick={() =>
                            setCityPages((prev) => ({
                              ...prev,
                              [productId]: prev[productId] - 1,
                            }))
                          }
                        >
                          Previous
                        </Button>
                        <span className='flex items-center'>
                          Page {cityCurrentPage} of {cityTotalPages}
                        </span>
                        <Button
                          disabled={cityCurrentPage === cityTotalPages}
                          onClick={() =>
                            setCityPages((prev) => ({
                              ...prev,
                              [productId]: prev[productId] + 1,
                            }))
                          }
                        >
                          Next
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Card>
          )
        })}

        {totalPages > 1 && (
          <div className='flex justify-center mt-4 gap-4'>
            <Button disabled={currentPage === 1} onClick={() => setCurrentPage((curr) => curr - 1)}>
              Previous
            </Button>
            <span className='flex items-center'>
              Page {currentPage} of {totalPages}
            </span>
            <Button disabled={currentPage === totalPages} onClick={() => setCurrentPage((curr) => curr + 1)}>
              Next
            </Button>
          </div>
        )}
      </Card>
    </Space>
  )
}

export default StockoutTable
