import React, { useEffect, useState } from 'react'
import { Select, Card, Row, Col, Form, Space, Button, Table, Tag } from 'gokwik-ui-kit'
import { getStorePincodeMapTable } from '@library/api'
import { updateBreadcrumbs } from '@gokwik/utilities'
import store from '@store/index'

const { Option } = Select

type Filters = {
  storeId: string
  cities: string[]
  states: string[]
}

const StorePincodeTable = () => {
  // State management
  const [data, setData] = useState([])
  const [dropDownData, setDropDownData] = useState([])
  const [filterData, setFilterData] = useState<Filters>({
    storeId: '',
    cities: [],
    states: [],
  })
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  // Fetch data function
  const fetchStorePincodeMapTable = async () => {
    try {
      setLoading(true)
      const response = await getStorePincodeMapTable({})
      setData(response?.stores || [])
      setDropDownData(response?.stores)
    } catch (error) {
      console.error('Error in calling storePincodeMapTable api', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    updateBreadcrumbs((prev) => [
      prev[0],
      {
        key: 'kwikcomm Pincode Mapping',
        href: '/kwikcomm/storePincodeMapTable',
        text: 'kwikcomm Pincode Mapping',
      },
    ])
    // fetchStorePincodeMapTable()
    handleApplyFilters({ storeId: 'zepto' })
  }, [])

  // Extract unique cities and states for filters
  const uniqueCities = Array.from(new Set(dropDownData.map((item) => item.city)?.filter(Boolean)))
  const uniqueStates = Array.from(new Set(dropDownData.map((item) => item.state)?.filter(Boolean)))

  // Filter handlers
  const handleApplyFilters = async (values) => {
    try {
      setLoading(true)
      const response = await getStorePincodeMapTable(values)
      setData(response?.stores || [])
    } catch (error) {
      console.error('Error in calling storePincodeMapTable api', error)
    } finally {
      setLoading(false)
    }
  }

  const handleResetFilters = () => {
    form.resetFields()
    setFilterData({ storeId: '', cities: [], states: [] })
    fetchStorePincodeMapTable()
  }

  // Table columns configuration
  const columns = [
    {
      title: 'Store ID',
      dataIndex: 'storeId',
      key: 'storeId',
    },
    {
      title: 'Pincodes',
      dataIndex: 'pincodes',
      key: 'pincodes',
      render: (pincodes) => (
        <Space size={[0, 4]} wrap>
          {pincodes.map((pincode) => (
            <Tag key={pincode} className='rounded-full px-3 py-1 bg-blue-100 text-blue-800'>
              {pincode}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ]

  return (
    <Space direction='vertical' size='large' className='w-full'>
      <Card className='w-full shadow-sm'>
        <Form
          form={form}
          onFinish={handleApplyFilters}
          layout='vertical'
          initialValues={{ storeId: 'zepto' }}
          className='mb-6'
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name='storeId' label='Store'>
                <Select placeholder='Select Store' allowClear>
                  {['blinkit', 'instamart', 'zepto'].map((store) => (
                    <Option key={store} value={store}>
                      {store.charAt(0).toUpperCase() + store.slice(1)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='cities' label='Cities'>
                <Select mode='multiple' placeholder='Select Cities' allowClear maxTagCount={2}>
                  {uniqueCities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='states' label='States'>
                <Select mode='multiple' placeholder='Select States' allowClear maxTagCount={2}>
                  {uniqueStates.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Space>
                <Button type='primary' htmlType='submit'>
                  Apply Filters
                </Button>
                <Button onClick={handleResetFilters}>Reset Filters</Button>
              </Space>
            </Col>
          </Row>
        </Form>

        <Table
          columns={columns}
          dataSource={data}
          rowKey='_id'
          loading={loading}
          scroll={{ x: 800, y: 380 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          locale={{
            emptyText: (
              <div className='text-center py-8'>
                <p>No data found</p>
              </div>
            ),
          }}
          className='shadow-sm'
        />
      </Card>
    </Space>
  )
}

export default StorePincodeTable
